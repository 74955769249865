import {
    BATTLEFEED_REQUESTED,
    BATTLEFEED_RESOLVED,
    BATTLEFEED_ERROR,
} from '../../actions/battlefeed/types';
import {
    STATE_RESOLVING,
    STATE_RESOLVED,
    STATE_ERROR,
} from '../../services/state/types';
import {
    updateFeed,
} from '../../services/feed'
import schema from '../schema';

const initialState = schema
    .set('feed', null)

/**
*
* The Battlefeed Reducer
*
* Responsible for managing the state and data of the battlefeed
*
*/
const battlefeedReducer = (state = initialState, action) => {

    switch (action.type) {

        case BATTLEFEED_REQUESTED:

            return state
                .set('status', STATE_RESOLVING)

        case BATTLEFEED_RESOLVED:

            return state
                .set('status', STATE_RESOLVED)
                .set('feed', updateFeed(state.get('feed'), action.payload));


        case BATTLEFEED_ERROR:

            return state
                .set('status', STATE_ERROR)
                .set('feed', null)

        default:

            return state;
    }
};

export { initialState };
    
export default battlefeedReducer;
