import {
    BATTLEFEED_REQUESTED,
    BATTLEFEED_RESOLVED,
    BATTLEFEED_ERROR
} from './types'

import http from '../../services/http'

const getBattlefeed = async () => async (dispatch, getState) => {

    const state = getState().battlefeed;

    if(state.get('resolving')) return;

    dispatch({
        type: BATTLEFEED_REQUESTED,
    })

    try {
        const payload = await http.getBattlefeed();

        return dispatch({
            type: BATTLEFEED_RESOLVED,
            payload,
        })
    }
    catch(error){
        dispatch({
            type: BATTLEFEED_ERROR
        })
    }

}

export default getBattlefeed;