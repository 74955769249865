import { createGlobalStyle } from 'styled-components';
import { viewport } from './styles'

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
    #botwars-battlefeed {
        font-family: 'Orbitron', sans-serif;
        // Set default box-sizing to 'border-box for all elements
        *, *::before, *::after {
            box-sizing: border-box;
            font-family: 'Orbitron', sans-serif;
        }
    }

    body {
        #botwars-battlefeed {
            p, h3, h4 {
                margin: 0;
                font-size: 12px;
                line-height: 16px;
            }

            h3, h4 {
                @media ${viewport.mediumMobile} {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media ${viewport.largeMobile} {
                    font-size: 18px;
                    line-height: 22px;
                }

                @media ${viewport.desktop} {
                    font-size: 24px;
                    line-height: 28px;
                }
            }

            h3 {
                text-align: center;
            }

            h4 {
                font-weight: bold;
                color: white;
            }

            p {
                color: #a4a4a4;

                @media ${viewport.mediumMobile} {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media ${viewport.largeMobile} {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media ${viewport.desktop} {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }
`