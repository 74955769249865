import moment from 'moment';

export const updateFeed = (currentFeed, newFeed) => {

    // Set currentFeed to an array if it is falsey
    if (!currentFeed) return newFeed.map((item, position) => ({
        ...item,
        position,
        id: getFeedItemId(item),
    }))

    const itemsToAdd = [];
    const duplicateIndexes = [];

    for (const [position, newFeedItem] of newFeed.entries()) {
        newFeedItem.id = getFeedItemId(newFeedItem);
        const duplicateIndex = currentFeed.findIndex(currentFeedItem => currentFeedItem.id === newFeedItem.id);
        if (duplicateIndex === -1) {
            itemsToAdd.push({
                ...newFeedItem,
                position,
            })
        } else {
            currentFeed[duplicateIndex].position = position;
            duplicateIndexes.push(duplicateIndex);
        }
    }

    for (const [index] of currentFeed.entries()) {
        if (duplicateIndexes.indexOf(index) === -1) {
            currentFeed[index].position = currentFeed[index].position + (process.env.REACT_APP_BATTLEFEED_LIMIT - duplicateIndexes.length);
        }
    }

    currentFeed = currentFeed.concat(itemsToAdd);

    return currentFeed.filter(currentFeedItem => currentFeedItem.position < 18);

} 

export const getFeedItemId = (item) => {
    const { userName, createdAt, } = item;
    return `${userName.replace(/\s/g, '_')}_${createdAt}`;
}

export const getTimeFromNow = (time) => {
    let timeFromNow = moment(time).fromNow(true);
    if (timeFromNow === 'a few seconds') return 'Now';
    if (timeFromNow === 'a minute') return '1min';
    if (timeFromNow === 'an hour') return '1hr';
    return timeFromNow.replace(new RegExp(/(\sseconds)/gm), 's').replace(new RegExp(/(\sminutes)/gm), 'mins').replace(new RegExp(/(\shours)/gm), 'hrs');
}