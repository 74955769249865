import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from '../reducers/reducers';

// Build middleware
const middleware = applyMiddleware(
    thunk,
);

let enhancer;

// Make our store with our reducers and middleware
if (process.env.REACT_APP_REDUX_DEVTOOLS === 'true') {
    enhancer = composeWithDevTools(
        middleware,
    );
} else {
    enhancer = compose(
        middleware,
    );
}

/**
 * Configures the store
 *
 * @param {*} preloadedState
 * @returns
 */
const configureStore = (preloadedState) => {

    const store = createStore(
        createRootReducer(), // root reducer with router state
        preloadedState,
        enhancer,
    )

    return store
};

export default configureStore;
