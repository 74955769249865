import React from 'react';
import ReactTimeout from 'react-timeout'
import { StyledApp } from './styles'
import FeedItem from './feed-item/FeedItem'

class App extends React.Component {

    componentDidMount() {
        const { getBattlefeed } = this.props;
        getBattlefeed()
    }

    componentDidUpdate(prevProps) {
        const { getBattlefeed } = this.props;
        setTimeout(() => {
            getBattlefeed()
        }, Math.floor(Math.random() * 12000) + 5000 )
    }

    render() {

        const { battlefeed } = this.props;

        return (
            <StyledApp id="botwars-battlefeed-container" role="list">
                {battlefeed && battlefeed.length && battlefeed.map((item, index) => <FeedItem user={item} key={index} />)}
            </StyledApp>
        )
    }
}


export default ReactTimeout(App);