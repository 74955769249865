//es6 polyfills required for IE11
import 'babel-polyfill';
// import 'unfetch/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './services/store';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import GlobalStyles from './components/GlobalStyles';

export const store = configureStore();


ReactDOM.render(

    <Provider store={store}>
        <GlobalStyles />
        <App />
    </Provider>,

    document.getElementById('botwars-battlefeed'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
