import styled, { keyframes } from 'styled-components'
import avatarFrameImg from '../../assets/avatar-frame.png'
import {
    viewport,
} from '../styles';

const feedItemHeight = {
    default: 85,
    desktop: 110,
}

const newBattlefeed = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const FeedItemWrapper = styled.div`
    position: absolute;
    top: ${({ position }) => position < process.env.REACT_APP_BATTLEFEED_LIMIT ? position * feedItemHeight.default : process.env.REACT_APP_BATTLEFEED_LIMIT * feedItemHeight.default}px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    animation: ${newBattlefeed} 0.25s ease-in-out normal;
    backdrop-filter: blur(10px);
    background-color: rgba(0,0,0, 0.3);
    transition: top 0.25s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    opacity: ${({ position }) => position < process.env.REACT_APP_BATTLEFEED_LIMIT ? 1 : 0};
    transform:  scale(${({ position }) => position < process.env.REACT_APP_BATTLEFEED_LIMIT ? 1 : 0.85});
    border: 1px solid #a4a4a4;
    border-radius: 2px;
    box-shadow: 0px 0px 5px #000;
    padding: 12px;

    @media ${viewport.desktop} {    
        top: ${({ position }) => position < process.env.REACT_APP_BATTLEFEED_LIMIT ? position * feedItemHeight.desktop : process.env.REACT_APP_BATTLEFEED_LIMIT * feedItemHeight.desktop}px;
    }
`

export const FeedItemAvatar = styled.figure`
    position: relative;
    flex: 0 0 42px;
    margin: 0;

    @media ${viewport.desktop} {
        flex: 0 0 65px;
    }

    img {
        max-width: 100%;
    }

    &:before {
        content: '';
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background: url(${avatarFrameImg}) center center no-repeat;
        background-size: cover;
    }
`

export const FeedItemDetails = styled.div`
    flex: 0 0 40%;
    text-align: left;

    @media ${viewport.mediumMobile} {
        flex: 0 0 50%;
    }
`

export const FeedItemUserName = styled.h4`
    padding: 0 8px 0;
    
    @media ${viewport.smallMobile} {
        overflow: hidden;
        white-space: nowrap
        text-overflow: ellipsis;
    }

    @media ${viewport.largeMobile} {
        padding: 0 12px 0;
    }
`

export const FeedItemMarket = styled.p`
    padding: 0 8px 0;

    @media ${viewport.smallMobile} {
        overflow: hidden;
        white-space: nowrap
        text-overflow: ellipsis;
    }

    @media ${viewport.largeMobile} {
        padding: 0 12px 0;
    }
`

const H3 = styled.h3`
    flex: 0 0 20%;
    padding: 0 4px 0 0;

    @media ${viewport.mediumMobile} {
        padding: 0 8px;
    }

    @media ${viewport.largeMobile} {
        padding: 0 16px;
    }
`

export const FeedItemSide = styled(H3)`
    color: ${({ children }) => children === "SELL" ? '#ff4040' : '#19f71f'} !important;;
    text-shadow: 0 0 8px ${({ children }) => children === "SELL" ? '#ff4040' : '#19f71f'};
`

export const FeedItemTime = styled(H3)`
    color: white !important;
`