import { connect } from 'react-redux'
import getBattlefeed from '../actions/battlefeed/getBattlefeed'
import App from '../components/App';

const mapStateToProps = (state) => ({
    battlefeed: state.battlefeed && state.battlefeed.get('feed'),
})

const mapDispatchToProps = (dispatch) => ({
    getBattlefeed: async () => { 
        await dispatch( await getBattlefeed())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)