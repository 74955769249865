import React from 'react'
import PropTypes from 'prop-types';
import {
    getTimeFromNow,
} from '../../services/feed'
import {
    FeedItemWrapper,
    FeedItemAvatar,
    FeedItemDetails,
    FeedItemUserName,
    FeedItemMarket,
    FeedItemSide,
    FeedItemTime,
} from './styles'

// const isEqual = (prevProps, nextProps) => {
//     return prevProps.user.position === nextProps.user.position;
// }

class FeedItem extends React.Component {

    state = {
        userName: null,
        market: null,
        avatarUrl: null,
        side: null,
        createdAt: null,
        position: null,
    }

    componentDidMount = () => {
        const { user } = this.props;
        this.setState({ ...user })
    }

    componentDidUpdate = () => {
        const { position, } = this.state;
        const { user, } = this.props;

        if(user.position !== position){
            this.setState({ position: user.position })
        }
    }

    render = () => {

        const { ...otherProps } = this.props;

        const { userName, avatarUrl, side, market, createdAt, position, } = this.state;

        return (
            <FeedItemWrapper position={position} role="listitem" {...otherProps}>

                <FeedItemAvatar><img alt={`Avatar for ${userName}`} src={avatarUrl} /></FeedItemAvatar>

                <FeedItemDetails>
                    <FeedItemUserName>{userName}</FeedItemUserName>
                    <FeedItemMarket>Deployed on {market}</FeedItemMarket>
                </FeedItemDetails>

                <FeedItemSide>{side}</FeedItemSide>

                <FeedItemTime>{getTimeFromNow(createdAt)}</FeedItemTime>

            </FeedItemWrapper>
        )
    }
}

FeedItem.propTypes = {
    user: PropTypes.object.isRequired,
}

export default FeedItem;
// export default React.memo(FeedItem, isEqual)