import axios from 'axios';

const http = {}

http.getBattlefeed = async () => {
    try {
        const response = await axios.get(`https://us-central1-botwarsmvp.cloudfunctions.net/api/battlefeed/${process.env.REACT_APP_BATTLEFEED_LIMIT}`);
        return response.data;
    }
    catch(error){
        console.log('Could not fetch Battlefeed', error);
        throw error;
    }
}

export default http;