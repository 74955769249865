import styled from 'styled-components';

const breakpoints = ['0px', '420px', '482px', '550px', '767px'];
  
export const viewport = {
    tinyMobile: `(min-width: ${breakpoints[0]})`,
    smallMobile: `(min-width: ${breakpoints[1]})`,
    mediumMobile: `(min-width: ${breakpoints[2]})`,
    largeMobile: `(min-width: ${breakpoints[3]})`,
    desktop: `(min-width: ${breakpoints[4]})`,
};

const appHeight = {
    default: '700px',
    desktop: '900px',
}

export const StyledApp = styled.div`
    position: relative;
    max-width: 625px;
    margin: 0 auto;
    height: ${appHeight.default};

    @media ${viewport.desktop} {
        height: ${appHeight.desktop};
    }

    * {
        box-sizing: border-box;
    }
`;